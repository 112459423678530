import VueCookies from 'vue-cookies'
import api from '@/api/admin'

export const state = {
    currentUser: null,
    loginError : null,
}

export const mutations = {
    setUser(state, payload) {
        state.currentUser = payload
        state.loginError = null
    },
    // 오류 내용에 따라 메세지 전달
    setError(state, payload) {
        state.loginError = payload
        state.currentUser = null
    },
    clearError(state) {
        state.loginError = null
    }
}

export const getters = {
    currentUser: state => state.currentUser,
    loginError: state => state.loginError,
}

export const actions = {
    async validate({ commit }){
        let result =false
        let accessToken = VueCookies.get('accessToken')
        let refreshToken = VueCookies.get('refreshToken')
        const userModel = await api.user()
        if(userModel == null || userModel == undefined){
            result = false
        }
        else if(userModel.role == 'admin' && userModel.username != undefined && accessToken != undefined){
            commit('setUser', userModel)
            result = true
        }
        else if(userModel.username == undefined && accessToken != undefined && refreshToken != undefined) {
           let refeshModel = await api.refreshToken({
                "refreshToken": refreshToken,
                "accessToken": accessToken
            })
            if(refeshModel.username != undefined) {
                commit('setUser', refeshModel)
                VueCookies.set('accessToken', refeshModel.accessToken, '10080h')
                VueCookies.set('refreshToken', refeshModel.refreshToken, '10080h')
                result = true
            }
        }
        return result
    },
    async login({ commit }, payload) {
        commit('clearError')
        try {
            const login = await api.Login({
                account: payload.username,
                password: payload.password,
                version:"9.9.9.9"
            })
            if(login == false || login.status!="Success"){
                commit('setError',login.message);
            }
            else if(login.status=="Success" && login.data.role !="admin"){
                commit('setError',"관리자 계정이 아닙니다");
            }
            else {
                commit('setUser', login.data)
                VueCookies.set('accessToken', login.data.accessToken, '10080h')
                VueCookies.set('refreshToken', login.data.refreshToken, '10080h')
            }
        }catch (e){
            //
        }
    },
    async logout({commit}){
        commit('setUser', null)
        VueCookies.remove('accessToken')
        VueCookies.remove('refreshToken')
    }
}

