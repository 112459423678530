import Vue from 'vue'
import axios from 'axios'
import VueCookies from 'vue-cookies'
import store from '@/state/store'

export const apiUrl = 'https://vidos.co.kr/api'
export const localUrl = 'https://localhost:5001/api'

Vue.prototype.$http = axios
axios.timeout = 180000

axios.interceptors.response.use(function (response) {
    return response;
}, async function (error) {
    //auth 오류에 한에서 만 작업을 진행
    if(error.response.status == 401){
        const isAccessToken = VueCookies.isKey('accessToken')
        const isRefreshToken = VueCookies.isKey('refreshToken')
        //로그아웃이 필요한 경우
        if(error.response.data =="Invalid token" ||
            (error.response.data.indexOf('Signature validation failed. ') > -1) ||
            (error.response.data.indexOf('IDX10214: Audience validation failed.') > -1) ||
            isAccessToken == false ||
            isRefreshToken == false){
            await store.dispatch('auth/logout')
            // location.href="/login"
        }
    }
    //아닐경우 아래와 같은 형태로 반환
    return Promise.reject({code:error.response.status,message:error.response.data});
});


export default {
    baseURL: apiUrl,

    jsonToQuery: function (param) {
        if (typeof param != 'object') {
            return false
        }

        var str = ''
        for (var key in param) {
            str += `${key}=${param[key]}&`
        }

        return str
    },
    request: async function (method, uri, data = undefined) {
        if (method == 'GET' && data != undefined) {
            uri += '?' + this.jsonToQuery(data)
        }

        var response = {}
        try {
            //   this.showProgress(10)
            const isToken = VueCookies.isKey('accessToken')
            if (method == 'GET') {
                response = await axios.get(`${this.baseURL}${uri}`, {
                    headers: {
                        Authorization: 'Bearer ' + VueCookies.get('accessToken')
                    }
                })
            } else if (method == 'POST') {
                if (isToken) {
                    response = await axios.post(`${this.baseURL}${uri}`, data, {
                        headers: {
                            Authorization: 'Bearer ' + VueCookies.get('accessToken')
                        }
                    })
                } else {
                    response = await axios.post(`${this.baseURL}${uri}`, data)
                }
            }
        } catch (e) {
            return e
        }
        if (response.data == undefined) {
            return false
        }

        var responseData = response.data
        if (responseData.payload != undefined) {
            responseData = responseData.payload
        }
        return responseData
    },
    mulitpartRequest: async function (uri, multiPartData) {
        var fd = new FormData()
        for (let key in multiPartData) {
            fd.append(key, data[key])
        }
        var response = {}
        try {
            response = await axios.post(`${this.baseURL}${uri}`, fd, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + VueCookies.get('accessToken')
                }
            })
        } catch (e) {
            return false
        }

        var data = response.data

        return data
    },
    fileRequest: async function (method, uri, multiPartData, type) {
        var fd = new FormData()
        fd.append('type', type)
        if (data.length == undefined) {
            fd.append('files', multiPartData)
        } else {
            for (var x = 0; x < multiPartData.length; x++) {
                fd.append('files', multiPartData.item(x))
            }
        }

        var response = {}
        try {
            response = await axios.post(`${this.baseURL}${uri}`, fd, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + VueCookies.get('accessToken')
                }
            })
        } catch (e) {
            return false
        }

        var data = response.data

        return data
    },
    jsonRequest: async function (method, uri, postData) {
        var response = {}
        try {
            response = await axios.post(
                `${this.baseURL}${uri}`,
                JSON.stringify(postData),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + VueCookies.get('accessToken')
                    }
                }
            )
        } catch (e) {
            return false
        }
        var data = response.data
        return data
    },
    jsonRequestDetail: async function (method, uri, postData) {
        var response = {}
        try {
            response = await axios.post(
                `${this.baseURL}${uri}`,
                JSON.stringify(postData),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + VueCookies.get('accessToken')
                    }
                }
            )
        } catch (e) {
            return e
        }
        var data = response.data
        return data
    },
    Login: async function(model){
        let result = await this.jsonRequest('POST','/Auth/Login',model)
        return result
    },
    user: async function(){
        let result = await this.request('GET','/Auth/user')
        return result
    },
    logout: async function(){
        let result = await this.jsonRequest('POST','/Auth/logout','')
        return result
    },
    GetUserList: async function(model){
        let result = await this.jsonRequest('POST','/Admin/GetUserList',model)
        return result
    },
    SetUserInfo: async function(model){
        let result = await this.jsonRequest('POST','/Admin/SetUserInfo',model)
        return result
    },
    SetUserPlan: async function(model){
        let result = await this.jsonRequest('POST','/Admin/SetUserPlan',model)
        return result
    },
    GetUserOpenMarket: async function(model){
        let result = await this.request('GET','/Admin/GetUserOpenMarket?userId='+model)
        return result
    },
    RemoveUserOpenMarket: async function(model){
        let result = await this.jsonRequest('POST','/Admin/RemoveUserOpenMarket',model)
        return result
    },
    SetUserTaobaoCount: async function(model){
        let result = await this.jsonRequest('POST','/Admin/SetUserTaobaoCount',model)
        return result
    },
    refreshToken: async function(model){
        let result = await this.jsonRequest('POST','/Auth/refreshToken',model)
        return result
    },
    GetPlan: async function(){
        let result = await this.request('GET','/Plan/GetPlan')
        return result
    },
    SetUserBasicInfo: async function(model){
        let result = await this.jsonRequest('POST','/Admin/SetUserBasicInfo',model)
        return result
    },
    SetUserOpenMarketLimit: async function(model){
        let result = await this.jsonRequest('POST','/Admin/SetUserOpenMarketLimit',model)
        return result
    },
    SetUserSourcingSiteLimit: async function(model){
        let result = await this.jsonRequest('POST','/Admin/SetUserSourcingSiteLimit',model)
        return result
    },
    GetUserOpenMarketLimit: async function(userId){
        let result = await this.request('GET','/Admin/GetUserOpenMarketLimit?userId='+userId)
        return result
    },
    GetUserSourcingSiteLimit: async function(userId){
        let result = await this.request('GET','/Admin/GetUserSourcingSiteLimit?userId='+userId)
        return result
    },
    setUserExpireDate: async function(model) {
        let result = await this.request('POST', '/Admin/SetUserPeriod', model);
        return result;
    }
}
