<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import appConfig from "@/app.config";

export default {
  name: "app",
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    }
  }
};
</script>

<style>
.sweet-title{
  margin-top: 24px !important;
}
.sweet-modal {
  overflow-y: hidden !important;
}

.sweet-modal-overlay{
  z-index:1050 !important;
}

</style>