import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Vuelidate from 'vuelidate'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import VueMask from 'v-mask'
import VueYoutube from 'vue-youtube'

import vco from "v-click-outside"

import router from './router'
import store from '@/state/store'
import i18n from './i18n'
import vueMoment from 'vue-moment'

import "@/assets/scss/app.scss";

Vue.config.productionTip = false
Vue.use(VueYoutube)
Vue.use(BootstrapVue)
Vue.use(vco)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2);
Vue.use(vueMoment)
Vue.use(VueMask)
Vue.use(require('vue-chartist'))
Vue.component('apexchart', VueApexCharts)
import VueLoading from 'vuejs-loading-plugin'

Vue.use(VueLoading)
Vue.use(VueLoading, {
  dark: true, // default false
  text: '작업 중...', // default 'Loading'
  loading: true, // default false
  // customLoader: myVueComponent, // replaces the spinner and text with your own
  background: 'rgb(255,255,255)', // set custom background
  classes: ['myclass'] // array, object or string
})


new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
